<script>

import { format } from 'date-fns'
import BigNumber from "bignumber.js"
import * as Sentry from "@sentry/vue"

export default {
  components: {
  },
  props: {
    matches: Array,
  },
  data() {
    return {
      headerHeight: 50,
      width: 200,
      rowHeight:  80,
    }
  },
  inject: [
  ],
  methods: {
  },
  computed: {
    timeline () {
      const spans = {}
      let lastPeriod = format(new Date(), 'yyMM')
      let matches = JSON.parse(JSON.stringify(this.matches))

      const members = []
      matches.map(match => {
        Object.values(match.RuleGroups).map(rg => {
          rg.Members.map(mb => {
            mb.TransactionType = match.TransactionType.Description
            members.push(mb)
          })
        })
      }) 


      members.map(mb => {
        const start = mb.FirstValidAccountingPeriodID
        let end = mb.LastValidAccountingPeriodID

        if (end == 9999) {
          end = null;
        }

        if (!end) end = lastPeriod

        if (start > lastPeriod) {
          lastPeriod = start
        } else if (end > lastPeriod) {
          lastPeriod = end
        }

        for (let ap of [start, end]) {
          if (spans[ap] === undefined) {
            spans[ap] = {
              splitTotals: {
                'Default': new BigNumber(0),
              },
              members: []
            }
          }
        }
      })

      members.map(mb => {
        let ap = mb.FirstValidAccountingPeriodID
        let end = mb.LastValidAccountingPeriodID

        if (end == 9999) {
          end = null;
        }

        if (!end) end = lastPeriod

        let periodCount = 0
        while (ap <= end) {
          if (spans[ap] !== undefined) {
            const rule = mb.TransactionReaccountRule
            const splitTotals = spans[ap].splitTotals
            if (splitTotals[mb.TransactionType] === undefined) { 
              splitTotals[mb.TransactionType] = new BigNumber(0)
            }
            splitTotals[mb.TransactionType] = 
              splitTotals[mb.TransactionType].plus(rule.SplitRate || 0)

            if (mb.spanCount == undefined) {
              mb.spanCount = 0
            }
            mb.spanCount++
            
            spans[ap].members.push(mb)
          }

          if (String(ap++).substring(2) == '12') {
            ap = Number(String(ap+100).substring(0, 2) + '01')
          }

          if (periodCount++ > 500) {
            console.warn('Max period loop')
            this.$notify({
              type: 'error',
              text: 'Something went wrong, please refresh and try again'
            }) 
            Sentry.captureException(new Error('Reached maximum period loop'))
            return {}
          }
        }
      })

      let prev = null
      let index = 0
      for (const period in spans) {
        spans[period].index = index++
        if (prev) {
          spans[prev].nextPeriod = period
        }

        prev = period
      }

      const membersRow = {}
      let row = 0
      let found = true

      while (found) {
        found = false

        let skip = 0
        for (const [period, span] of Object.entries(spans)) {
          if (skip > 0) {
            skip--
            continue
          }
          
          for (const member of span.members) {
            if (member.FirstValidAccountingPeriodID != period) continue

            const rule = member.TransactionReaccountRule
            const ruleId = rule.TransactionReaccountRuleID

            if (membersRow[ruleId] !== undefined) continue
            membersRow[ruleId] = row
            skip = member.spanCount - 1
            found = true
            member.spanIdx = span.index
            member.rowIdx = row
            break;
          }
        }

        if (!found) break
        row++
      }

      return {
        rowCount: row,
        membersRow,
        spans,
        members,
      }
    },
  },
  mounted() {

  }
}
</script>

<template lang="pug">

div
  div.timeline(
    :style=`{
      height: (headerHeight + (rowHeight * timeline.rowCount) + 10) + 'px', 
    }`   
  )
    
    div.header(
      v-for="span, period, index in timeline.spans"
      :style=`{
        left: (width * index) + 'px',
        width: width + 'px',
        height: headerHeight - 20 + 'px', 
      }`
    )
      | {{ period|formatAccountingPeriod }}


    div.member(
      v-for="member in timeline.members"
      :style=`{
        top: ((rowHeight * member.rowIdx) + headerHeight) + 'px',
        left: (width * member.spanIdx) + 5 + 'px',
        width: (width * member.spanCount) - 10 + 'px',
        height: rowHeight - 10  + 'px',
      }`
    )
      | {{ member.TransactionType }}
      br
      | {{ member.FirstValidAccountingPeriodID|formatAccountingPeriod }} - {{ member.LastValidAccountingPeriodID|formatAccountingPeriod }}
      br
      | {{ member.TransactionReaccountRule.StatementSpec.Beneficiary.FullName }} - {{ member.TransactionReaccountRule.SplitRate|percentage }} 
      br
      

</template>

<style scoped>

.timeline {
  position: relative;
  overflow-x: auto;
  max-height: 85vh;

  & > div {
    padding: 3px; 
    position: absolute;
  }  
  .header {
    top: 0;
    border-right: 1px solid rgb(144, 144, 144);
   }

   .member {
    border-radius: 5px;
    border: 1px solid #17abe2;
    background-color: white;
    overflow: hidden;
   }
}

</style>
  